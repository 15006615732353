<template>
    <el-dialog
        class="editDialog"
        :append-to-body="false"
        :modal="false"
        :fullscreen="true"
        width="600px"
        :visible.sync="showEditDialog"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        @close="$emit('update:isShow', false)"
        :show-close="true"

    >

        <div class="content" v-loading="loading">
            <el-form label-width="0" :model="form" :rules="rules" ref="form" size="mini">
                <el-descriptions  :column="4"  border>

                    <el-descriptions-item>
                        <template slot="label">表名</template>
                        <el-form-item prop="tableName" ref="tableName">
                            <el-input v-model="form.tableName" placeholder="请输入" @keyup.enter.native="submit"></el-input>
                        </el-form-item>
                    </el-descriptions-item>

                    <el-descriptions-item>
                        <template slot="label">备注</template>
                        <el-form-item prop="tableNote" ref="tableNote">
                            <el-input v-model="form.tableNote" placeholder="请输入" @keyup.enter.native="submit"></el-input>
                        </el-form-item>
                    </el-descriptions-item>

                </el-descriptions>

                <el-table ref="formTable" :data="form.fields" height="string" border  header-cell-class-name="formTableHeader">

                    <el-table-column label="字段名" align="center" width="120">
                        <template slot-scope="scope">
                            <el-form-item :prop="'fields[' + scope.$index + '].fieldName'" ref="fieldName" :rules="rules.fieldName">
                                <el-input :disabled="!!scope.row.id" v-model="scope.row.fieldName" placeholder="请输入" @keyup.enter.native="submit"></el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>

                    <el-table-column label="别名" align="center">
                        <template slot-scope="scope">
                            <el-form-item  :prop="'fields[' + scope.$index + '].fieldLabel'" ref="fieldLabel">
                                <el-input v-model="scope.row.fieldLabel" placeholder="请输入" @keyup.enter.native="submit"></el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>

                    <el-table-column label="字段类型" align="center">
                        <template slot-scope="scope">
                          <el-form-item :prop="'fields[' + scope.$index + '].fieldType'" ref="fieldType">
                              <Dictionary :disabled="!!scope.row.id" v-model="scope.row.fieldType" valKey="val" code="FIELDTYPE"></Dictionary>
                          </el-form-item>
                        </template>
                    </el-table-column>

                    <el-table-column label="字段长度" align="center">
                        <template slot-scope="scope">
                            <el-form-item :prop="'fields[' + scope.$index + '].fieldLen'" ref="fieldLen">
                                <el-input-number :disabled="!!scope.row.id"  v-model="scope.row.fieldLen" controls-position="right"></el-input-number>
                            </el-form-item>
                        </template>
                    </el-table-column>

                    <el-table-column label="xml字段对应前缀" align="center">
                        <template slot-scope="scope">
                            <el-form-item :prop="'fields[' + scope.$index + '].tableXmlField'" ref="tableXmlField">
                                <el-input v-model="scope.row.tableXmlField" placeholder="请输入" @keyup.enter.native="submit"></el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>

                    <el-table-column label="xml字段" align="center">
                        <template slot-scope="scope">
                            <el-form-item :prop="'fields[' + scope.$index + ']. xmlField'" ref="xmlField">
                                <el-input v-model="scope.row.xmlField" placeholder="请输入" @keyup.enter.native="submit"></el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>


                    <el-table-column align="center" width="80" fixed="right">
                        <el-button slot="header" type="primary" size="mini" icon="el-icon-plus" @click="add"></el-button>
                        <template slot-scope="scope">
                            <el-button slot="header" v-if="!scope.row.id" type="danger" size="mini" icon="el-icon-delete" @click="del(scope)"></el-button>
                        </template>
                    </el-table-column>

                </el-table>

            </el-form>
        </div>

        <div slot="footer" v-show="!loading">
            <el-button type="primary" :loading="submitLoading" @click="submit">保存</el-button>
            <el-button type="info" plain @click="$emit('update:isShow', false)">取消</el-button>
        </div>

    </el-dialog>

</template>
<script>

import mixinsForm from '@/mixins/form'
import Sortable from "sortablejs";
import {TABLENAME} from "@/util/regular";
export default {
    components: {
        Dictionary: () => import("@/components/Dictionary.vue"),
        ChoiceData: () => import("@/components/ChoiceData.vue"),
    },
    mixins: [mixinsForm],
    data() {
        return {
            elFormTable:null,
            form: {
                fields:[],
            },
            api:{
                save:this.$api['admin/metadataTable'].save,
            },
            rules: {
                tableName: [
                    { required: true, message: '请输入表名', trigger:['blur','change'] },
                    { pattern:TABLENAME.value,message:'表名只能包含字母数字或者下划线并且以字母开头',trigger:['blur','change']},
                ],
                fieldType: [{ required: true, message: '请输入字段类型', trigger:['blur','change'] }],
                fieldName:[
                    { required: true, message: '请输入字段名', trigger: ['blur','change'] },
                    { pattern:TABLENAME.value,message:'字段名只能包含字母数字或者下划线并且以字母开头',trigger:['blur','change']},
                    {
                        trigger: ['change', 'blur'], validator: (rule, value, callback) => {
                            var arr = [];
                            this.form.fields.some(r=>{
                                arr.push(r.fieldName);
                            });
                            for(let i = arr.indexOf(value)+1;i<arr.length;i++){
                                if(arr[i]==value){
                                    return callback(new Error('字段名称不能重复'));
                                }
                            }
                            callback();
                        }
                    }
                ],
            },
        };
    },
    watch: {
        isShow: {
            immediate: true,//初始化立即执行
            handler: function (newVal) {
                this.showEditDialog = newVal;
                if (newVal) {
                    this.$nextTick(() => {

                        if(!this.el){
                            this.elFormTable = this.$refs.formTable.$el.querySelector('.el-table__body-wrapper tbody');
                            new Sortable(this.elFormTable, {
                                animation: 150,
                                ghostClass: '.el-table__row',
                                onEnd:r=> {
                                    var currRow = this.form.fields.splice(r.oldIndex, 1)[0];
                                    this.form.fields.splice(r.newIndex, 0, currRow);
                                    var fields = this.form.fields
                                    this.form.fields = []
                                    this.$nextTick(() => {
                                        this.form.fields = fields
                                    })
                                }
                            });
                        }
                    });
                }
            }
        },
    },
    created() {},
    mounted(){},
    methods: {
        init(options){

            if(options.id){
                this.$api['admin/metadataTable'].getField(options.id).then( res => {

                    this.form = res.data.info.combination({fields:res.data.list})
                    this.loading = false;

                }).catch(err=>{
                    console.log(err);
                })
            }else {
                this.form = options.deepClone();
                this.loading = false;
            }

        },
        add(){
            this.form.fields.push({
                fType:'text',
                listIsShow:'true',
                sortable:'false',
                formIsShow:'true',
                isEdit:'false',
                required:'false',
            })
        },
        del(scope){
            this.form.fields.splice(scope.$index,1);
        },
        submit(){
            this.$refs.form.validate((valid,obj) => {
                if (valid) {

                    this.submitLoading = true;
                    this.$api['admin/metadataTable'].save(this.form).then( res => {
                        this.submitLoading = false;
                        this.refresh();
                        this.$message.success("操作成功！");

                    }).catch(err=>{
                        this.submitLoading = false;
                        console.log(err);
                    })
                }else {
                    this.scrollView(obj)
                };
            });
        },
    },
};
</script>
<style scoped lang='scss'>
@import "@/styles/config.scss";
.el-form {
    display:block;
    height:100%;
    /deep/.el-form-item {
        margin:0;
        padding:0;
        &.is-error {
            position:relative;
            bottom:6px;
            .el-input__inner {
                border-color:#f00;
            }
            .el-form-item__error {

                transform: scale(1,.8);
            }
        }
        .el-form-item__content {
            line-height: 28px;
        }
    }
    /deep/.el-descriptions {
        white-space: nowrap;
        td,th {
            text-align:center;
        }
    }
    /deep/.el-table {
        height:calc(100% - 55px);
        .formTableHeader {
            background: #fafafa!important;
        }
    }
}
</style>
